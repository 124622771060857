body {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
	margin: 0;
	--block-accent-color: #00BFA6;
}
a {
	text-decoration: none;
	color: inherit;
}
.app {
	margin: 0;
	display: flex;
	flex-direction: row;
	width: 100%;
}
.row {
	display: flex;
	flex-direction: row;
}
.column {
	display: flex;
	flex-direction: column;
}
.flex-wrap {
	flex-wrap: wrap;
}

/*FONTS*/
.xsmall {
	font-size: 0.75rem;
}
.small {
	font-size: 0.9rem;
}
.thin {
	font-weight: 300;
}
.bold {
	font-weight: 700;
}
.login-form {
	margin: 2rem auto;
}
.error-image {
	width: 50%;
	margin: 4rem;
	opacity: 0.7;
}
.error {
	color: red;
}

/*STYLING*/
.flex-1 {
	flex: 1;
}
.flex-2 {
	flex: 2;
}
.flex-3 {
	flex: 3;
}
.margin: {
	margin: 1rem;
}
.no-margin {
	margin: 0;
}
.margin-top: {
	margin-top: 1rem;
}
.margin-right: {
	margin-right: 1rem;
}
.margin-bottom: {
	margin-bottom: 1rem;
}
.margin-left: {
	margin-left: 1rem;
}
.padding {
	padding: 1rem;
}
.no-padding {
	padding: 0rem;
}
.padding-top: {
	padding-top: 1rem;
}
.padding-right: {
	padding-right: 1rem;
}
.padding-bottom: {
	padding-bottom: 1rem;
}
.padding-left: {
	padding-left: 1rem;
}
.remove-wrapper-padding {
	padding: 4px 8px !important;
}
.discount-input-width {
	width: 4rem;
}
.full-width {
	width: 100%;
}
.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-space-between {
	justify-content: space-between;
}
.align-items-center {
	align-items: center;
}
.align-self-center {
	align-self: center;
}
.text-align-center {
	text-align: center;
}
.text-align-left {
	text-align: left;
}
.text-align-right {
	text-align: right;
}
.word-break-break-all {
	word-break: break-all;
}

/*PREVIEWS*/
.preview-input {
	position: sticky;
	align-self: flex-start;
	top: 0px;
}
.preview {
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0 0 2rem;
}

.preview blockquote {
  border-left: 3px solid #4254FF;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: italic;
  margin: 1rem 2rem;
  padding: 0.5rem 2rem;
  position: relative;
  transition: .2s border ease-in-out;
}
.preview blockquote::after {
	content: "\f35f";
  position: absolute;
  top: 50%;
  left: -0.5rem;
  color: #4254FF;
  font-family: "Ionicons";
  font-style: normal;
  line-height: 1rem;
  text-align: center;
  text-indent: -2px;
  width: 1rem;
  margin-top: -0.5rem;
  transition: .2s all ease-in-out, .4s transform ease-in-out;
}
.preview blockquote::before {
	content: "";
  position: absolute;
  top: 50%;
  left: -4px;
  height: 2rem;
  background-color: #FFF;
  width: 5px;
  margin-top: -1rem;
}
/*.preview blockquote {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-weight: 100;
  color: #fff;
  padding: 1rem;
  background-color: #4254FF;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
}
.preview blockquote::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #4254FF;
  border-bottom: 0;
  border-right: 0;
  margin-right: -10px;
  margin-bottom: -20px;
}*/
.preview p {
  margin: 1rem 0;
}
.preview h1, h2 {
  font-size: 1.5rem;
  margin: 3rem 0 1rem !important;
}
.preview ul li {
  margin: .5rem 0 !important;
}
.preview ol li {
  margin: .5rem 0 !important;
}
.preview img {
  width: 100% !important;
}
.in-progress {
	--block-background-color: #FEC00A;
}
.inactive {
	--block-background-color: #eee;
	opacity: 0.5;
}
.sample {
	--block-background-color: #3545EE;
	color: white;
}
.warning {
	--block-background-color: red;
	color: white;
}
.avatar {
	width: 80px;
	height: 80px;
}