.overview-core-analytics {
	display: flex;
	flex-direction: row;
	align-self: center;
	width: 100%;
}
.overview-core-analytics-box {
	display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: .5rem 1.5rem;
  padding: 1rem 3rem;
  border: 3px solid #3545EE;
  border-radius: 5px;
}
.core-analytics {
	color: #3545EE;
	font-size: 3rem;
}